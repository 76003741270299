<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker v-model="closetime" type="datetime" format="YYYY-MM-DD HH:mm:ss" placeholder="计划关闭时间" />
      <el-select v-model="listQuery.closeIP" v-loading="listLoading" :placeholder="`选择IP`" class="filter-item"
        style="width: 130px">
        <el-option v-for="item in allIp" :key="item" :label="item" :value="item" />
      </el-select>
      <el-button v-waves class="filter-item" type="primary" icon="el-icon-search" @click="handleReloadAll">
        {{ `热更所有进程` }}
      </el-button>
      <el-button v-waves class="filter-item" type="danger" icon="el-icon-search" @click="handleCloseAll">
        {{ `关闭所有进程` }}
      </el-button>

    </div>
    <el-table v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 100%">
      <el-table-column align="center" label="ID" width="60">
        <template #default="{ row }">
          <span>{{ row.Id }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="IP" width="160px">
        <template #default="{ row }">
          <span>{{ row.OuterIP }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="Port" width="80px">
        <template #default="{ row }">
          <span>{{ row.InnerPort }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="AppName" width="160px">
        <template #default="{ row }">
          <span>{{ row.ProcessDes }}</span>
        </template>
      </el-table-column>
      <el-table-column width="180px" align="center" label="LastStartDate">
        <template #default="{ row }">
          <span>{{ row.ServerTime }}</span>
        </template>
      </el-table-column>
      <el-table-column width="130px" align="center" label="MemorySize">
        <template #default="{ row }">
          <span>{{ row.MemorySize }}</span>
        </template>
      </el-table-column>
      <el-table-column class-name="status-col" label="Status" width="110">
        <template #default="{ row }">
          <el-tag :type="row.StatusDes">
            {{ row.StatusDes }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column align="center" label="Entity" width="130">
        <template #default="{ row }">
          <el-button type="primary" size="mini" @click="handleEntityInfo(row)">
            {{ row.EntityCount }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column width="130px" align="center" label="Player">
        <template #default="{ row }">
          <span>{{ row.PlayerCount }}</span>
        </template>
      </el-table-column>
      <el-table-column width="130px" align="center" label="Session">
        <template #default="{ row }">
          <span>{{ row.SessionCount }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="Actions" width="230">
        <template #default="{ row }">
          <el-button v-if="row.StatusDes === 'published'" type="primary" size="mini" v-loading="OperateLoading"
            @click="handleOperate(row, 0)">
            {{ $t('server_manage.reload') }}
          </el-button>
          <el-button v-if="row.StatusDes === 'published'" size="mini" type="success" v-loading="OperateLoading"
            @click="handleOperate(row, 1)">
            {{ $t('server_manage.restart') }}
          </el-button>
          <el-button v-if="row.StatusDes === 'draft'" size="mini" type="success" v-loading="OperateLoading"
            @click="handleOperate(row, 2)">
            {{ $t('server_manage.start') }}
          </el-button>
          <el-button v-if="row.StatusDes === 'published'" size="mini" type="danger" v-loading="OperateLoading"
            @click="handleOperate(row, 3)">
            {{ $t('server_manage.shutdown') }}
          </el-button>
        </template>
      </el-table-column>

    </el-table>
    <el-dialog v-model="dialogEntityInfoVisible" :title="'EntityInfo'">
      <el-table :data="TempEntityInfo" border fit highlight-current-row style="width: 100%;">
        <el-table-column :label="'EntityName'" prop="id" align="center" width="300px">
          <template #default="{ row }">
            <span>{{ row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'EntityCount'" width="180px" align="center">
          <template #default="{ row }">
            <span>{{ row.count }}</span>
          </template>
        </el-table-column>

      </el-table>
    </el-dialog>

  </div>
</template>

<script lang="ts">
import { ServerManage_GMGetProcessState, ServerManage_GMHandleAllProcess, ServerManage_GMProcessEdit } from '@/apis/server_manage';
import { TodayStart } from "@/utils/timer";
import { ElMessage, ElMessageBox } from 'element-plus';
import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  setup() {
    const { t } = useI18n()
    const dataMap = reactive({
      list: Array<IProcessData>(),
      listLoading: true,
      closetime: TodayStart(),
      OperateLoading: false,
      allIp: Array<string>(),
      dialogEntityInfoVisible: false,
      TempEntityInfo: Array<{ name: string, count: number }>(),
      listQuery: {
        page: 1,
        limit: 10,
        closeIP: "",
      },
    })

    const getList = async () => {
      dataMap.list.length = 0
      dataMap.allIp.length = 0
      dataMap.listLoading = true
      let cbmsg = await ServerManage_GMGetProcessState()
      console.log(cbmsg)
      if (cbmsg?.Error !== 0) {
        ElMessage.error(cbmsg?.Message)
        return
      }
      const items = JSON.parse(cbmsg?.Message!) as IProcessData[]
      items.forEach(v => {
        if (v.Status) {
          v.StatusDes = 'published'
        } else {
          v.StatusDes = 'draft'
        }
        v.PlayerCount = 0
        v.SessionCount = 0
        if (dataMap.allIp.indexOf(v.OuterIP) == -1) {
          dataMap.allIp.push(v.OuterIP)
        }
        if (v.EntityInfo) {
          v.EntityDic = JSON.parse(v.EntityInfo)
          v.EntityCount = 0
          Object.values(v.EntityDic!).forEach(count => {
            v.EntityCount! += count
          })
          v.PlayerCount = v.EntityDic!['Player'] || 0;
          v.SessionCount = v.EntityDic!['Session'] || 0;
        }
        dataMap.list.push(v)
      })
      dataMap.listQuery.closeIP = dataMap.allIp[0];
      dataMap.listLoading = false
    }

    const handleEntityInfo = (row: IProcessData) => {
      dataMap.TempEntityInfo.length = 0
      for (let k in row.EntityDic) {
        dataMap.TempEntityInfo.push({
          name: k,
          count: row.EntityDic[k]
        })
      }
      dataMap.TempEntityInfo.sort((a, b) => {
        if (a.name > b.name) {
          return 1
        } else {
          return -1
        }
      })
      dataMap.dialogEntityInfoVisible = true
    }

    const handleOperate = (row: IProcessData, OperateType: number) => {
      let operDes = ['Reload', 'ReStart', 'Start', 'ShutDown'][OperateType]
      let typeForm = ['warning', 'success', 'success', 'error'][OperateType]
      let OperateInterver = [1000, 15000, 5000, 5000][OperateType]
      ElMessageBox.confirm(
        `Confirm to ${operDes} this Process<${row.Id}>[${row.ProcessDes}] ?`,
        `${operDes} Server`,
        {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: typeForm as any
        }
      ).then(async () => {
        dataMap.OperateLoading = true
        let cbmsg = await ServerManage_GMProcessEdit(
          {
            OperateType: OperateType,
            ProcessId: parseInt(row.Id)
          }
        )
        if (cbmsg?.Error == 0) {
          setTimeout(async () => {
            await getList()
            dataMap.OperateLoading = false
          }, OperateInterver)
          ElMessage.success(`Process<${row.Id}>[${row.ProcessDes}] ${operDes} Success`)
        } else {
          ElMessage.error(`Process<${row.Id}>[${row.ProcessDes}] ${operDes} Fail`)
        }
      }).catch(err => {
        console.error(err)
      })
    }
    const handleReloadAll = async () => {
      ElMessageBox.confirm(
        `Confirm to Reload All Process In [${dataMap.listQuery.closeIP}] ?`,
        `Reload Server`,
        {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      ).then(async () => {
        dataMap.OperateLoading = true
        await ServerManage_GMHandleAllProcess({
          TimeSpan: dataMap.closetime.getTime() / 1000,
          HandleType: 0,
        });
      }).catch(err => {
        console.error(err)
      })

    }
    const handleCloseAll = async () => {
      ElMessageBox.confirm(
        `Confirm to Close All Process In [${dataMap.listQuery.closeIP}] ?`,
        `Close Server`,
        {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      ).then(async () => {
        dataMap.OperateLoading = true
        await ServerManage_GMHandleAllProcess({
          TimeSpan: dataMap.closetime.getTime() / 1000,
          HandleType: 3,
        });
      }).catch(err => {
        console.error(err)
      })

    }

    onMounted(() => {
      getList()
    })
    return {
      t, ...toRefs(dataMap),
      handleEntityInfo,
      handleOperate,
      handleReloadAll,
      handleCloseAll
    }
  }
})
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}

.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
</style>
