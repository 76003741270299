
import { ServerManage_GMGetProcessState, ServerManage_GMHandleAllProcess, ServerManage_GMProcessEdit } from '@/apis/server_manage';
import { TodayStart } from "@/utils/timer";
import { ElMessage, ElMessageBox } from 'element-plus';
import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  setup() {
    const { t } = useI18n()
    const dataMap = reactive({
      list: Array<IProcessData>(),
      listLoading: true,
      closetime: TodayStart(),
      OperateLoading: false,
      allIp: Array<string>(),
      dialogEntityInfoVisible: false,
      TempEntityInfo: Array<{ name: string, count: number }>(),
      listQuery: {
        page: 1,
        limit: 10,
        closeIP: "",
      },
    })

    const getList = async () => {
      dataMap.list.length = 0
      dataMap.allIp.length = 0
      dataMap.listLoading = true
      let cbmsg = await ServerManage_GMGetProcessState()
      console.log(cbmsg)
      if (cbmsg?.Error !== 0) {
        ElMessage.error(cbmsg?.Message)
        return
      }
      const items = JSON.parse(cbmsg?.Message!) as IProcessData[]
      items.forEach(v => {
        if (v.Status) {
          v.StatusDes = 'published'
        } else {
          v.StatusDes = 'draft'
        }
        v.PlayerCount = 0
        v.SessionCount = 0
        if (dataMap.allIp.indexOf(v.OuterIP) == -1) {
          dataMap.allIp.push(v.OuterIP)
        }
        if (v.EntityInfo) {
          v.EntityDic = JSON.parse(v.EntityInfo)
          v.EntityCount = 0
          Object.values(v.EntityDic!).forEach(count => {
            v.EntityCount! += count
          })
          v.PlayerCount = v.EntityDic!['Player'] || 0;
          v.SessionCount = v.EntityDic!['Session'] || 0;
        }
        dataMap.list.push(v)
      })
      dataMap.listQuery.closeIP = dataMap.allIp[0];
      dataMap.listLoading = false
    }

    const handleEntityInfo = (row: IProcessData) => {
      dataMap.TempEntityInfo.length = 0
      for (let k in row.EntityDic) {
        dataMap.TempEntityInfo.push({
          name: k,
          count: row.EntityDic[k]
        })
      }
      dataMap.TempEntityInfo.sort((a, b) => {
        if (a.name > b.name) {
          return 1
        } else {
          return -1
        }
      })
      dataMap.dialogEntityInfoVisible = true
    }

    const handleOperate = (row: IProcessData, OperateType: number) => {
      let operDes = ['Reload', 'ReStart', 'Start', 'ShutDown'][OperateType]
      let typeForm = ['warning', 'success', 'success', 'error'][OperateType]
      let OperateInterver = [1000, 15000, 5000, 5000][OperateType]
      ElMessageBox.confirm(
        `Confirm to ${operDes} this Process<${row.Id}>[${row.ProcessDes}] ?`,
        `${operDes} Server`,
        {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: typeForm as any
        }
      ).then(async () => {
        dataMap.OperateLoading = true
        let cbmsg = await ServerManage_GMProcessEdit(
          {
            OperateType: OperateType,
            ProcessId: parseInt(row.Id)
          }
        )
        if (cbmsg?.Error == 0) {
          setTimeout(async () => {
            await getList()
            dataMap.OperateLoading = false
          }, OperateInterver)
          ElMessage.success(`Process<${row.Id}>[${row.ProcessDes}] ${operDes} Success`)
        } else {
          ElMessage.error(`Process<${row.Id}>[${row.ProcessDes}] ${operDes} Fail`)
        }
      }).catch(err => {
        console.error(err)
      })
    }
    const handleReloadAll = async () => {
      ElMessageBox.confirm(
        `Confirm to Reload All Process In [${dataMap.listQuery.closeIP}] ?`,
        `Reload Server`,
        {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      ).then(async () => {
        dataMap.OperateLoading = true
        await ServerManage_GMHandleAllProcess({
          TimeSpan: dataMap.closetime.getTime() / 1000,
          HandleType: 0,
        });
      }).catch(err => {
        console.error(err)
      })

    }
    const handleCloseAll = async () => {
      ElMessageBox.confirm(
        `Confirm to Close All Process In [${dataMap.listQuery.closeIP}] ?`,
        `Close Server`,
        {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      ).then(async () => {
        dataMap.OperateLoading = true
        await ServerManage_GMHandleAllProcess({
          TimeSpan: dataMap.closetime.getTime() / 1000,
          HandleType: 3,
        });
      }).catch(err => {
        console.error(err)
      })

    }

    onMounted(() => {
      getList()
    })
    return {
      t, ...toRefs(dataMap),
      handleEntityInfo,
      handleOperate,
      handleReloadAll,
      handleCloseAll
    }
  }
})
